import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { nextTick } from 'vue'
import { store } from '@/store/index'
import { guestPWord, guestUser } from '@/components/Utility/Base'
import AuthService from '@/services/AuthService'
// import Home from '../views/Home.vue'
// import adminHome from '../views/adminHome.vue'
import home from '../views/Home.vue'
import { admZubPth } from '@/components/Utility/aXdYmZ'

const DEFAULT_TITLE = 'Dansk Dart Union'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Hjem' },
    component: home
  },
  {
    path: '/reset-adgangskode/',
    name: 'ResetPassword',
    meta: { title: 'Nulstil adgangskode' },
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue'),
    props: route => ({ query: route.query.code, params: true })
  },
  {
    path: '/kurven/',
    name: 'Cart',
    meta: { title: 'Kurv' },
    component: () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue')
  },
  {
    path: '/kassen/',
    name: 'Checkout',
    meta: { title: 'Kassen' },
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue')
  },
  {
    path: '/kassen/tak-for-kob/',
    name: 'CheckoutSuccess',
    meta: { title: 'Tak' },
    component: () => import(/* webpackChunkName: 'checkoutSuccess' */ '../views/CheckoutSuccess.vue')
  },
  {
    path: '/kalender/',
    name: 'Calendar',
    meta: { title: 'Kalender' },
    component: () => import(/* webpackChunkName: "kalender" */ '../views/Calendar.vue')
  },
  {
    path: '/sponsorer/',
    name: 'Sponsorlist',
    meta: { title: 'Sponsorer' },
    component: () => import(/* webpackChunkName: "sponsorlist" */ '../views/Sponsorlist.vue')
  },
  {
    path: '/staevner/',
    name: 'Competitionlist',
    meta: { title: 'Stævner' },
    component: () => import(/* webpackChunkName: "competitionlist" */ '../views/Competitionlist.vue')
  },
  {
    path: '/staevner/:slug',
    name: 'Competition',
    meta: { title: 'Stævne' },
    component: () => import(/* webpackChunkName: "competition" */ '../views/Competition.vue')
  },
  {
    path: '/staevner/ranglister',
    name: 'Rankingmain',
    meta: { title: 'Ranglister' },
    component: () => import(/* webpackChunkName: "rankingmain" */ '../views/Rankingmain.vue')
  },
  {
    path: '/staevner/ranglister/:rankingSlug',
    name: 'Rankinglist',
    meta: { title: 'Rangliste' },
    component: () => import(/* webpackChunkName: "rankinglist" */ '../views/Rankinglist.vue')
  },
  {
    path: '/arrangementer/',
    name: 'Eventlist',
    meta: { title: 'Arrangementer' },
    component: () => import(/* webpackChunkName: "eventlist" */ '../views/Eventlist.vue')
  },
  {
    path: '/arrangementer/:slug',
    name: 'Event',
    meta: { title: 'Arrangement' },
    component: () => import(/* webpackChunkName: "event" */ '../views/Event.vue')
  },
  {
    path: '/klubber/',
    name: 'Clubs',
    meta: { title: 'Klubber' },
    component: () => import(/* webpackChunkName: "clubs" */ '../views/Clubs.vue')
  },
  {
    path: '/klubber/:slug/',
    name: 'theClub',
    meta: { title: 'Klub' },
    component: () => import(/* webpackChunkName: "clubinfo" */ '../views/ClubInfo.vue')
  },
  {
    path: '/medlemmer/',
    name: 'Members',
    meta: { title: 'Medlemmer' },
    component: () => import(/* webpackChunkName "members" */ '../views/Members.vue')
  },
  {
    path: '/medlemmer/nyt-medlem/',
    name: 'theMemberRegistration',
    meta: { title: 'Nyt medlem' },
    component: () => import(/* webpackChunkName "memberregistration" */ '../views/MemberRegistration.vue')
  },
  {
    path: '/medlemmer/:slug',
    name: 'Memberinfo',
    meta: { title: 'Medlemsinfo' },
    component: () => import(/* webpackChunkName "memberinfo" */ '../views/Memberinfo.vue')
  },
  {
    path: '/nyheder/',
    name: 'Nyhedsarkiv',
    meta: { title: 'Nyhedsarkiv' },
    component: () => import(/* webpackChunkName: "nyhedsarkiv" */ '../views/Nyhedsarkiv.vue')
  },
  {
    path: '/nyheder/:indlaegSlug/',
    name: 'Nyheder',
    meta: { title: 'Nyhed' },
    component: () => import(/* webpackChunkName: "nyheder" */ '../views/nyheder.vue')
  },
  {
    path: '/turneringer/',
    name: 'FrontEndTournaments',
    meta: { title: 'Turneringer' },
    component: () => import(/* webpackChunkName: "tournaments" */ '../views/Tournaments.vue')
  },
  {
    path: '/turneringer/:tournamentSlug',
    name: 'MatchSchedule',
    meta: { title: 'Turnering' },
    component: () => import(/* webpackChunkName: "matchSchedule" */ '../views/MatchSchedule.vue')
  },
  {
    path: '/turnering/turneringslivekampe',
    name: 'LiveMatchScheduleList',
    meta: { title: 'Turneringslivekampe' },
    component: () => import(/* webpackChunkName: "liveMatchScheduleList" */ '../views/LiveMatchScheduleList.vue')
  },
  {
    path: '/turnering/turneringslivekampe/:id',
    name: 'LiveMatchScore',
    meta: { title: 'LiveMatchScore' },
    component: () => import(/* webpackChunkName: "liveMatchScheduleList" */ '../views/LiveMatchScore.vue'),
    props: true
  },
  {
    path: '/turnering/todaysmatch',
    name: 'TodaysMatchScheduleList',
    meta: { title: 'Turneringstodaysmatch' },
    component: () => import(/* webpackChunkName: "liveMatchScheduleList" */ '../views/TodaysMatchScheduleList.vue')
  },
  {
    path: '/turneringer/kampseddel/score/:matchSetId',
    name: 'CompletedMatchScore',
    meta: { title: 'CompletedMatchScore' },
    component: () => import('../views/CompletedMatchScore.vue'),
    props: true
  },
  {
    path: '/turneringer/kampseddel/:matchId',
    name: 'TournamentMatch',
    meta: { title: 'Kampseddel' },
    component: () => import(/* webpackChunkName: "tournamentMatch" */ '../views/TournamentMatch.vue'),
    props: true
  },
  {
    path: '/turneringer/kampseddel/:matchId/matchboard',
    name: 'TournamentMatchBoard',
    meta: { title: 'Kampseddel' },
    component: () => import(/* webpackChunkName: "tournamentadvancedmatchsetroundsmode" */ '@/views/TournamentMatchBoard.vue'),
    props: true
  },
  {
    path: '/turneringer/statistik',
    name: 'Statistics',
    meta: { title: 'Statistik' },
    component: () => import(/* webpackChunkName "statistics" */ '../views/Statistics.vue')
  },
  {
    path: '/tools/tournamentadvancedmatchset',
    name: 'Tournamentadvancedmatchsetroundsmode',
    meta: { title: 'Avanceret turneringskamp' },
    component: () => import(/* webpackChunkName: "tournamentadvancedmatchsetroundsmode" */ '@/views/TournamentAdvancedMatchSetRoundsMode.vue')
  },
  {
    path: '/:pathSlug1?/:pathSlug2?/:pathSlug3?/:slug/',
    name: 'Pages',
    meta: { title: 'Side' },
    props: true,
    component: () => import(/* webpackChunkName: "pages" */ '../views/Pages.vue')
  },
  {
    path: `/${admZubPth}/`,
    name: 'AdminHome',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "adminhome" */ '../views/admin/adminHome.vue')
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  // {
  //   path: '/adminhome',
  //   name: 'AdminDashboard',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "adminhome" */ '../views/AdminDashboard.vue')
  // },
  {
    path: `/${admZubPth}/brugere`,
    name: 'Brugere',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "users" */ '@/views/admin/Users.vue')
  },
  {
    path: `/${admZubPth}/media-arkiv`,
    name: 'MediaArkiv',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "mediaArchive" */ '@/views/admin/MediaArchive.vue')
  },
  {
    path: `/${admZubPth}/medlemmer`,
    name: 'Medlemmer',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "members" */ '@/views/admin/Members.vue')
  },
  {
    path: `/${admZubPth}/klubber`,
    name: 'Klubber',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "clubs" */ '@/views/admin/Clubs.vue')
  },
  {
    path: `/${admZubPth}/indlaeg`,
    name: 'Indlaeg',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "posts" */ '@/views/admin/Posts.vue')
  },
  {
    path: `/${admZubPth}/sider`,
    name: 'Sider',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "pages" */ '@/views/admin/Pages.vue')
  },
  {
    path: `/${admZubPth}/menuer`,
    name: 'Menuer',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "menus" */ '@/views/admin/Menus.vue')
  },
  {
    path: `/${admZubPth}/events`,
    name: 'Events',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "events" */ '@/views/admin/Events.vue')
  },
  {
    path: `/${admZubPth}/staevner`,
    name: 'Competitions',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "competitions" */ '@/views/admin/Competitions.vue')
  },
  {
    path: `/${admZubPth}/sponsorere`,
    name: 'Sponsors',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "sponsors" */ '@/views/admin/Sponsors.vue')
  },
  {
    path: `/${admZubPth}/landshold`,
    name: 'LandsHold',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "landshold" */ '@/views/admin/LandsHold.vue')
  },
  {
    path: `/${admZubPth}/turneringer`,
    name: 'Tournaments',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "tournaments" */ '@/views/admin/Tournaments.vue')
  },
  {
    path: `/${admZubPth}/domaener`,
    name: 'Domains',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "domains" */ '@/views/admin/Domains.vue')
  },
  {
    path: `/${admZubPth}/produkter`,
    name: 'Products',
    meta: { title: 'Admin' },
    component: () => import(/* webpackChunkName: "products" */ '@/views/admin/Products.vue')
  },
  {
    path: '/payment/epay',
    name: 'Epay',
    meta: { title: 'ePay' },
    component: () => import(/* webpackChunkName: "epay" */ '@/views/Epay.vue')
  },
  {
    path: '/medlemsportal/login',
    name: 'Login',
    meta: { title: 'Login' },
    component: () => import(/* webpackChunkName: "login" */ '@/views/memberportal/Login.vue')
  },
  {
    path: '/medlemsportal/:userSlug',
    name: 'MemberPortalMain',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName: "user" */ '@/views/memberportal/MemberPortalMain.vue')
  },
  {
    path: '/medlemsportal/:userSlug/rediger',
    name: 'EditYourInformation',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName: "edituser" */ '@/views/memberportal/EditYourInformation.vue')
  },
  {
    path: '/medlemsportal/:userSlug/rediger-klub',
    name: 'EditClubInformation',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName: "editclub" */ '@/views/memberportal/EditClubInformation.vue')
  },
  {
    path: '/medlemsportal/:userSlug/klubmedlemmer',
    name: 'ClubMembers',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName: "clubmembers" */ '@/views/memberportal/ClubMembers.vue')
  },
  {
    path: '/medlemsportal/:userSlug/nyt-medlem',
    name: 'NewMember',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName: "newmember" */ '@/views/memberportal/NewMember.vue')
  },
  {
    path: '/medlemsportal/:userSlug/ordrehistorik',
    name: 'OrderHistory',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName: "orderhistory" */ '@/views/memberportal/OrderHistory.vue')
  },
  {
    path: '/medlemsportal/:userSlug/kurser',
    name: 'Courses',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName "courses" */ '@/views/memberportal/Courses.vue')
  },
  {
    path: '/medlemsportal/:userSlug/klubarrangementer',
    name: 'ClubEvents',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName "clubevents" */ '@/views/memberportal/ClubEvents.vue')
  },
  {
    path: '/medlemsportal/:userSlug/shop',
    name: 'Shop',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName "shop" */ '@/views/memberportal/Shop.vue')
  },
  {
    path: '/medlemsportal/:userSlug/klubkaptajn',
    name: 'ClubCaptains',
    meta: { title: 'Medlemsportal' },
    component: () => import(/* webpackChunkName "clubCaptains" */ '@/views/memberportal/ClubCaptains.vue')
  },
  // {
  //   path: '/medlemsportal/:userSlug/clubtournaments',
  //   name: 'ClubTournaments',
  //   meta: { title: 'Medlemsportal' },
  //   component: () => import(/* webpackChunkName "clubTournaments" */ '@/views/memberportal/ClubTournaments.vue')
  // },
  // {
  //   path: '/medlemsportal/:userSlug/clubtournamentmatch',
  //   name: 'ClubTournamentMatchList',
  //   meta: { title: 'Medlemsportal' },
  //   component: () => import(/* webpackChunkName "clubTournamentMatchList" */ '@/views/memberportal/ClubTournamentMatchList.vue')
  // },
  // {
  //   path: '/medlemsportal/:userSlug/clubpointrules',
  //   name: 'ClubPointRules',
  //   meta: { title: 'Medlemsportal' },
  //   component: () => import(/* webpackChunkName "clubPointRules" */ '@/views/memberportal/ClubPointRules.vue')
  // },
  {
    path: '/karantaene-medlemsliste',
    name: 'Quarantine',
    meta: { title: 'Karantæne' },
    component: () => import(/* webpackChunkName "quarantine" */ '@/views/Quarantine.vue')
  },
  // Otherwise redirect to home.
  { path: '/:catchAll(.*)', name: 'NotFound', component: home, meta: { requiresAuth: false } }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      // console.log('moving to top of the page')
      // window.scroll({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // })
      window.scrollTo(0, 0)
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const loggedInStatus = localStorage.getItem('status')
  const apiToken = localStorage.getItem('apitoken')
  console.log('Login status: ' + loggedInStatus?.toString())
  // console.log('API token: ' + apiToken?.toString())

  if (loggedInStatus === undefined || loggedInStatus === null || loggedInStatus === false.toString() || apiToken === undefined || apiToken === null || apiToken.length < 1) {
    /* if (to.name !== 'Login') {
      return { name: 'Login' }
    } */
    console.log('router:beforeEach(): Trying to perform login ...')

    try {
      const loginResponse = await AuthService.login(guestUser, guestPWord)
      // console.log('[router:beforeEach()] loginResponse = ' + JSON.stringify(loginResponse))
      localStorage.setItem('logintype', false.toString())
    } catch (err) {
      // this.error = err
      console.log(err)
      next(false)

      return
    }
  }

  next()
})

router.afterEach(async (to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    let title = DEFAULT_TITLE

    if (to.meta.title) {
      title = `${to.meta.title} - ${title}`
    }
    console.log('Setting doc title = ' + title)

    document.title = title
  })
})

export default router
