
import { Vue } from 'vue-class-component'
import { admZubPth } from '@/components/Utility/aXdYmZ'

export default class App extends Vue {
  public noSidebar (routeName: string) : boolean {
    // console.log('[noSidebar()] routeName = ' + routeName)

    if (routeName === 'theClub' || routeName === 'Statistics' || routeName === 'TournamentMatchBoard') {
      return true
    }

    return false
  }

  public noHeader (routeName: string) : boolean {
    // console.log('[noSidebar()] routeName = ' + routeName)

    if (routeName === 'TournamentMatchBoard') {
      return true
    }

    return false
  }

  public rtPthAdmEval (inVal: any) : boolean {
    const uCqSatVeX = admZubPth.toString()
    const retVal = inVal.toString().includes(('/' + `${uCqSatVeX}`))

    return retVal
  }
}
